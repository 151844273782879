import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/nail-salon.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Nail Salons | Camelo"
        metaDescription="Put shift work at your fingertips and cut off manual staff management. Track your nail salon staff right on your phone with the Camelo app."
        header="Put shift work at your fingertips."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
